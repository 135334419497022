import { useQuery } from "@tanstack/react-query";
import { useAtom } from "jotai";
import Button from "~/components/Button";
import SectionDataRow from "~/components/SectionDataRow";
import VehicleIcon from "~/components/icons/VehicleIcon";
import vehicleBodyTypeOptions from "~/config/formSelectionOptions/vehicleBodyTypeOptions";
import { useNavigate, useParams } from "~/router";
import { dealershipAtom } from "~/state";
import { transactionQuery } from "../_queries/transactionQuery";

const TransactionPurchaseVehicle = () => {
  const navigate = useNavigate();
  const [dealership] = useAtom(dealershipAtom);
  const { transactionId } = useParams("/dashboard/transaction/:transactionId");
  const { data } = useQuery(
    transactionQuery(transactionId, dealership?.activeDealershipPerms)
  );
  const transaction = data?.transaction;
  const tradeVehicle = transaction?.tradeVehicle;
  const purchaseVehicle = transaction?.vehicle;

  const handleEditVehicle = (vehicleType: "PURCHASE" | "TRADE") => {
    navigate(
      {
        pathname: "/dashboard/transaction/:transactionId/vehicle/:vehicleType",
        search:
          vehicleType === "PURCHASE"
            ? !purchaseVehicle
              ? "?create=true"
              : undefined
            : !tradeVehicle
              ? "?create=true"
              : undefined,
      },
      { params: { transactionId, vehicleType } }
    );
  };

  return (
    <div className="rounded-2xl bg-white shadow-md p-8 space-y-8">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row space-x-4 justify-between w-full">
          <div className="flex flex-row space-x-2 items-center">
            <div className="relative">
              <VehicleIcon className="w-5 aspect-square text-dark-gray" />
            </div>

            <h2 className="text-heading-2 text-very-dark-gray">
              Vehicle for Purchase
            </h2>
          </div>

          <Button
            variant="TEXT_ONLY"
            onClick={() => handleEditVehicle("PURCHASE")}
          >
            {purchaseVehicle ? "Edit" : "Add"}
          </Button>
        </div>

        {/**
         * TODO: Hook up CARFAX
         */}
        {/* <Button variant="SECONDARY">CARFAX</Button> */}
      </div>

      <div className="grid grid-cols-2 lg:grid-cols-3">
        <div className="flex flex-col gap-2">
          <SectionDataRow
            label="Type"
            value={
              purchaseVehicle ? (purchaseVehicle?.isUsed ? "Used" : "New") : "-"
            }
          />

          <SectionDataRow
            label="Stock No."
            value={purchaseVehicle?.stockNumber ?? "-"}
          />

          <SectionDataRow label="VIN#" value={purchaseVehicle?.vin ?? "-"} />

          <SectionDataRow
            label="Body Type"
            value={
              vehicleBodyTypeOptions.find(
                (o) => o.value === purchaseVehicle?.bodyType
              )?.label ?? "-"
            }
          />
        </div>

        <div className="flex flex-col gap-2">
          <SectionDataRow label="Make" value={purchaseVehicle?.make ?? "-"} />

          <SectionDataRow label="Model" value={purchaseVehicle?.model ?? "-"} />

          <SectionDataRow label="Year" value={purchaseVehicle?.year ?? "-"} />

          <SectionDataRow
            label="Mileage"
            value={purchaseVehicle?.mileage?.toLocaleString() ?? "-"}
          />

          <SectionDataRow label="Trim" value={purchaseVehicle?.trim ?? "-"} />
        </div>
      </div>
    </div>
  );
};

export default TransactionPurchaseVehicle;
